body, p {
    color: $darkgrey;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px; }
b, strong {
    font-weight: 700; }
h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    font-weight: 700;
    width: 100%; }
h1, h2 {
    margin-bottom: 30px;
    font-size: 34px;
    text-transform: uppercase; }
h3 {
    font-size: 30px; }
a {
    color: $ortekblue;
    transition: ($standardtransition);
    &:hover {
        text-decoration: none;
        color: $lightblue; }
    &.button-cstm {
        display: inline-block;
        text-transform: uppercase;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        padding: 10px 20px;

        &.cstm-white {
            color: $ortekblue;
            background-color: #ffffff;
            &:hover {
                color: #ffffff;
                background-color: $ortekblue; } }
        &.cstm-dark {
            color: #ffffff;
            background-color: $ortekblue;
            &:hover {
                color: #ffffff;
                background-color: $darkgrey; } } } }

.logo {
    width: 150px; }

.navbar-light .navbar-toggler {
    border: none; }
.navbar-light .navbar-toggler-icon {
    background-image: none;
    color: $darkgrey; }

.plain-text {
    margin-top: 50px;
    margin-bottom: 60px; }

.text-twincolor {
    margin-bottom: 60px;
    h1, h2, h3 {
        text-align: center; }
    .col-12 {
        padding: 20px 15px 20px 15px;
        &:first-child {
            background: url(../img/twincolor-bg.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            p {
                color: #fff; } } } }

.text-w-bg-img {
    padding-bottom: 60%;
    display: block;
    position: relative;
    &.extra-img {
        margin-top: 50px;
        .plain-text {
            margin-bottom: 0px;
            margin-top: 0px; } }
    &::after {
        content: "";
        background: url(../img/LANDKREISE.jpg);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.5;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1; }
    .col-12 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        a {
            &.button-cstm {
                margin-top: 25px; } } } }

@media screen and (min-width: 768px) {
    h1, h2 {
        font-size: 48px; }
    .text-twincolor {
        .col-12 {
            display: flex;
            align-items: center; } }
    .text-w-bg-img {
        padding-bottom: 60px;
        .col-12 {
            justify-content: start; } } }

@media screen and (min-width: 992px) {
    .dropdown-toggle {
        &::after {
            display: none; } }
    .navbar-expand-lg {
        .navbar-brand {
            &.logo {
                margin-right: 160px; } }
        .navbar-nav {
            text-transform: uppercase;
            width: 100%;
            justify-content: space-between;
            .nav-item {
                position: relative;
                &.active, &:hover {
                    & > a {
                        &::before {
                            border-top: 2px solid $ortekred;
                            top: -4px; }
                        &::after {
                            border-top: 2px solid $ortekblue;
                            top: -29px; } } }

                a {
                    font-size: 18px;
                    &::before, &::after {
                        content: "";
                        display: block;
                        position: relative;
                        width: 100%; } }
                .dropdown-menu {
                    a {
                        font-size: 16px; } } } } }
    .text-w-bg-img {
        padding-bottom: 160px;
        &::after {
            opacity: 1; } } }

@media screen and (min-width: 1200px) {
    .text-w-bg-img {
        padding-bottom: 270px; } }
