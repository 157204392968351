.header {
    .row {
        h1 {
            position: absolute;
            color: #fff;
            font-size: 38px;
            text-shadow: 3px 3px 25px rgba(0,0,0,0.3); }
        .header-img {
            height: 170px;
            object-fit: cover;
            width: 100%; } } }
