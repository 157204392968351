.teaser-boxes {
    margin-bottom: 30px;
    a.teaser-box {
        display: block;
        margin-bottom: 30px;
        .box-title {
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            color: $darkgrey;
            display: block;
            padding: 5px 0px; }
        .overlay-box {
            border-top: 3px solid $ortekred;
            height: 9px;
            content: '';
            border-bottom: 3px solid $ortekblue;
            p, span {
                display: none; } } } }
@media screen and (min-width: 768px) {
    .teaser-boxes {
        a.teaser-box {
            position: relative;
            &:hover {
                .box-title {
                    position: absolute;
                    z-index: 457;
                    padding: 15px 10px 5px 10px;
                    color: #fff;
                    top: 0;
                    font-size: 18px; }
                .overlay-box {
                    position: absolute;
                    top: 0px;
                    min-height: 100%;
                    height: auto;
                    background: rgba(0, 0, 0, 0.8);
                    padding: 50px 10px 35px 10px;
                    p, span {
                        display: block;
                        color: #fff;
                        font-size: 18px; }
                    span {
                        &.link-style {
                            position: absolute;
                            bottom: 10px;
                            text-transform: uppercase;
                            font-family: 'Roboto Condensed', sans-serif;
                            font-weight: 700; } } } } } }
    .subsite {
        .teaser-boxes {
            h2 {
                display: none; }
            .col-md-3 {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%; } } } }

@media screen and (min-width: 992px) {
    .teaser-boxes {
        a.teaser-box {
            &:hover {
                .box-title {
                    font-size: 22px; }
                .overlay-box {
                    padding: 50px 10px 50px 10px;
                    p, span {
                        font-size: 22px; } } } } }
    .subsite {
        .teaser-boxes {
            .col-md-3 {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%; }
            .teaser-box {
                .box-title {
                    font-size: 18px; }
                &:hover {
                    .box-title {
                        font-size: 18px; }
                    .overlay-box {
                        span, p {
                            font-size: 18px; } } } } } } }

@media screen and (min-width: 1600px) {
    .subsite {
        .teaser-boxes {
            .teaser-box {
                .box-title {
                    font-size: 22px; }
                &:hover {
                    .box-title {
                        font-size: 22px; }
                    .overlay-box {
                        span, p {
                            font-size: 22px; } } } } } } }

