.carousel-item {
    img {
        height: 180px;
        object-fit: cover; }
    .carousel-caption {
        h5 {
            font-size: 22px;
            text-shadow: 3px 3px 25px rgba(0,0,0,0.3); } } }

@media screen and (min-width: 768px) {
    .carousel {
        margin-bottom: 15px;
        .carousel-item {
            img {
                height: 240px; } }
        .carousel-caption {
            h5 {
                font-size: 35px; }
            p {
                color: #fff; } } } }

@media screen and (min-width: 768px) {
       .carousel {
            .carousel-item {
                img {
                    height: 320px; } }
            .carousel-caption {
                h5 {
                    font-size: 44px; } } } }
