.subnav {
    background: url(../img/footer-bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0px 0px 35px 0px;
    .subnav-item, .subnav-headline {
        display: block;
        color: #fff;
        text-transform: uppercase;
        border: 3px solid rgba(0, 0, 0, 0.0);
        padding: 6px 15px;
        a {
            color: #fff;
            &:hover {
                color: $lightblue; } } }
    a.subnav-item, a.subnav-headline {
        &:hover, &.active {
            border-top: 3px solid $ortekred;
            border-bottom: 3px solid $ortekblue; } }
    .subnav-headline {
        font-weight: 700;
        font-size: 26px;
        margin-top: 40px;
        margin-bottom: 20px; } }
