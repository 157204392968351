.footer {
    background: url(../img/footer-bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px 0px 35px 0px;
    color: #fff;
    .footer-logo {
        width: 70%;
        display: block;
        margin: 0px auto 20px auto; }
    span {
        display: block; }
    p {
        color: #fff;
        font-size: 18px; }
    a {
        display: block;
        color: #ffffff;
        font-size: 18px;
        margin-bottom: 10px;
        &:hover {
            text-decoration: none;
            color: $lightblue; } }
    .footer-headline {
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 10px;
        margin-top: 35px;
        text-transform: uppercase; }
    .footer-menu-l {
        p {
            text-align: left;
            margin-top: 35px; }
        a {
            text-transform: uppercase; } }
    .footer-menu-r {
        a {
            display: none;
            text-transform: uppercase;
            &.footer-headline {
                display: block; } } } }
.footer-imprint {
    font-size: 18px;
    padding: 10px 0px; }

@media screen and (min-width: 576px) {
    .footer {
        .footer-logo {
            width: 50%; } } }

@media screen and (min-width: 768px) {
    .footer {
        .footer-logo {
            width: 75%;
            margin: 0px 0px 20px 0px; }
        .footer-menu-r {
            a {
                display: block; } } } }

@media screen and (min-width: 992px) {
    .footer {
        .footer-logo {
            width: 60%; } } }

@media screen and (min-width: 1200px) {
    .footer {
        .footer-logo {
            width: 200px; } } }
