// Variables
//
// Copy settings from `node_modules/bootstrap/scss/_variables.scss` into this file to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$darkgrey: #333;
$lighterblue: #8ecdff;
$lightblue: #2da2ff;
$ortekblue: #0000ff;
$ortekred: #ff0000;
$standardtransition: all .5s ease-in-out;