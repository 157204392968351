.vorteile {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 22px;

    .vorteil-box {
        width: 100%;
        &:nth-child(even) {
            a {
                background-color: $lightblue; } }
        &:nth-child(odd) {
            a {
                background-color: $lighterblue; } }
        a {
            color: #fff;
            padding: 10px 10px;
            width: 100%;
            &:hover {
                background-color: $darkgrey;
                text-decoration: none; }
            i + span {
                display: flex; }
            i {
                width: 25%; }
            span {
                width: 75%; } } } }
@media screen and (min-width: 768px) {
    .vorteile {
        .vorteil-box {
            width: auto;
            border-top: 3px solid #ff0000;
            border-bottom: 3px solid #0000ff;
            width: 30%;
            margin-bottom: 15px;
            a {
                color: $darkgrey;
                background-color: transparent!important;
                &:hover {
                    background-color: $darkgrey!important;
                    color: #fff; }
                i {
                    width: 100%; }
                span {
                    width: auto; } } } } }
@media screen and (min-width: 992px) {
    .vorteile {
        .vorteil-box {
            width: 18%; } } }
